import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { IntroAgencyCommon, IntroCommon } from './Model/IntroModel';
import map from '../../asset/img/MAP.png';
import background from '../../asset/img/img-9.jpg';
import IntroButton from './Control/IntroButton';
import { Link } from 'react-router-dom';
const areaCommon = {
  vi:
    [
      {
        title: 'HÀ NỘI',
        position: {
          top: 0,
          right: 0
        },
      },
      {
        title: 'HƯNG YÊN',
        position: {
          top: '19%',
          left: '0'
        }
      },
      {
        title: 'HẢI PHÒNG',
        position: {
          top: '25%',
          right: '0'
        }
      },
      {
        title: 'ĐÀ NẴNG',
        position: {
          top: '56%',
          left: '0'
        }
      },
      {
        title: 'TP. HỒ CHÍ MINH',
        position: {
          top: '76%',
          left: '0'
        }
      },
      {
        title: 'ĐỒNG NAI',
        position: {
          top: '72%',
          right: '0'
        }
      }
    ]
  ,
  en: [
    {
      title: 'HA NOI',
      position: {
        top: 0,
        right: 0
      },
    },
    {
      title: 'HUNG YEN',
      position: {
        top: '19%',
        left: '0'
      }
    },
    {
      title: 'HAI PHONG',
      position: {
        top: '25%',
        right: '0'
      }
    },
    {
      title: 'DA NANG',
      position: {
        top: '56%',
        left: '0'
      }
    },
    {
      title: 'HO CHI MINH CITY',
      position: {
        top: '76%',
        left: '0'
      }
    },
    {
      title: 'DONG NAI',
      position: {
        top: '72%',
        right: '0'
      }
    }
  ]
};

const handleHoverItemMap = () => {
  document.querySelectorAll('.intro-agency__map__area__main').forEach((item, index) => {
    item.addEventListener('mouseover', () => {
      document.querySelectorAll('.intro-agency__map__image__dots')[index].style.backgroundColor = '#00558d';
      document.querySelectorAll('.intro-agency__map__image__dots__line__square')[index].style.borderColor = '#00558d';
      document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background
        = document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background.replace('#ffffff', '#00558')
      if (document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].classList.contains('intro-agency__map__image__dots__line__cross-bottom'))
        document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background = `linear-gradient(
                to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1.2px),
                #00558d 50%,
                rgba(0, 0, 0, 0) calc(50% + 1.2px),
                rgba(0, 0, 0, 0) 100%
            )`;
      else
        document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background = `linear-gradient(
                to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1.2px),
                #00558d 50%,
                rgba(0, 0, 0, 0) calc(50% + 1.2px),
                rgba(0, 0, 0, 0) 100%
            )`;
    });
    item.addEventListener('mouseout', () => {
      document.querySelectorAll('.intro-agency__map__image__dots')[index].style.backgroundColor = '#ffffff';
      document.querySelectorAll('.intro-agency__map__image__dots__line__square')[index].style.borderColor = '#ffffff';
      if (document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].classList.contains('intro-agency__map__image__dots__line__cross-bottom'))
        document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background = `linear-gradient(
                to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1.2px),
                #ffffff 50%,
                rgba(0, 0, 0, 0) calc(50% + 1.2px),
                rgba(0, 0, 0, 0) 100%
            )`;
      else
        document.querySelectorAll('.intro-agency__map__image__dots__line__cross')[index].style.background = `linear-gradient(
                to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1.2px),
                #ffffff 50%,
                rgba(0, 0, 0, 0) calc(50% + 1.2px),
                rgba(0, 0, 0, 0) 100%
            )`;
    });
  });
};

export default function IntroAgencyPage(props) {
  const [area, setArea] = useState(null);
  const { language } = props;
  const [dataAgency, setDataAgency] = useState(IntroAgencyCommon[language].filterAgency);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function handleDataAgency() {
      let rs;
      if (area)
        rs = IntroAgencyCommon[language].filterAgency.filter((item) => {
          return item.area === area;
        })
      else
        rs = IntroAgencyCommon[language].filterAgency
      setDataAgency(rs);
    }
    // if (!area) return;
    handleHoverItemMap();
    handleDataAgency();
  }, [area, language])
  return (
    <Helmet title={IntroAgencyCommon[language].title}>
      <Breadcrum data={[
        {
          title: IntroCommon[language].title,
          path: `/${language}${IntroCommon[language].path}`
        },
        IntroAgencyCommon[language].title]}
      ></Breadcrum>
      <TitleBreadcrum title={IntroAgencyCommon[language].title}></TitleBreadcrum>
      <div className='intro-generation' style={{ backgroundImage: `url(${background})` }}>
        <div className='intro-generation__overlay'></div>
        <div className='intro-agency'>
          <div className='container'>
            <h2 className='intro-generation__title'>
              {IntroAgencyCommon[language].subtitle}
            </h2>
            <div className='intro-agency__map'>
              <div className='intro-agency__map__image'>
                <img src={map} alt='map'></img>
                <div className='intro-agency__map__image__dots' style={{ top: '13%', left: '38%' }}>
                  <div className='intro-agency__map__image__dots__line-right'>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-top'>

                    </div>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-top'>

                    </div>
                  </div>
                </div>
                <div className='intro-agency__map__image__dots' style={{ top: '15.5%', left: '41%' }}>
                  <div className='intro-agency__map__image__dots__line-left' style={{ top: '100%', height: '30px' }}>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-bottom'>

                    </div>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-top' style={{ width: '5%' }}>

                    </div>
                  </div>
                </div>
                <div className='intro-agency__map__image__dots' style={{ top: '16%', left: '47%' }}>
                  <div className='intro-agency__map__image__dots__line-right' style={{ top: '100%' }}>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-bottom'>

                    </div>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-bottom'>

                    </div>
                  </div>
                </div>
                <div className='intro-agency__map__image__dots' style={{ top: '53%', left: '62%' }}>
                  <div className='intro-agency__map__image__dots__line-left' style={{ top: '100%', height: '30px' }}>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-bottom'>

                    </div>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-top' style={{ width: '5%' }}>

                    </div>
                  </div>
                </div>
                <div className='intro-agency__map__image__dots' style={{ top: '83%', left: '49%' }}>
                  <div className='intro-agency__map__image__dots__line-left' style={{ height: '30px' }}>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-top'>

                    </div>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-bottom' style={{ width: '5%' }}>

                    </div>
                  </div>
                </div>
                <div className='intro-agency__map__image__dots' style={{ top: '81%', left: '56%' }}>
                  <div className='intro-agency__map__image__dots__line-right' style={{ height: '40px' }}>
                    <div className='intro-agency__map__image__dots__line__cross intro-agency__map__image__dots__line__cross-top' style={{ width: '10%' }}>

                    </div>
                    <div className='intro-agency__map__image__dots__line__square intro-agency__map__image__dots__line__square-top'>

                    </div>
                  </div>
                </div>
              </div>
              <div className='intro-agency__map__area'>
                <div className='intro-agency__map__area__main__all' style={{ top: '0', left: '0' }} onClick={
                  () => {
                    setArea(null);
                    document.getElementsByClassName('intro-agency__label')[0].scrollIntoView({
                      behavior: 'smooth'
                    });
                  }}>
                  {IntroAgencyCommon[language].all} ({IntroAgencyCommon[language].filterAgency.length})
                </div>
                {areaCommon[language].map((item, index) => {
                  return (
                    <div className='intro-agency__map__area__main' key={index}
                      style={item.position}
                      onClick={() => {
                        setArea(item.title);
                        document.getElementsByClassName('intro-agency__label')[0].scrollIntoView({
                          behavior: 'smooth'
                        });
                      }}
                    >
                      {item.title} ({IntroAgencyCommon[language].filterAgency.filter(itemChild => itemChild.area === item.title).length})
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='intro-agency__label'>
            {!area ? IntroAgencyCommon[language].labelAll : `${IntroAgencyCommon[language].areaTitle} ${area}`}
          </div>
          <div className='container'>
            <div className='intro-agency__main'>
              <Row gutter={{ xs: 5, sm: 5, md: 20, lg: 20 }}>
                {dataAgency.map((item, index) => {
                  if (item.logo)
                    return (
                      <Col className='intro-agency__main-container' lg={24} md={24} sm={24} xs={24} key={index + item.name}>
                        <Row className='intro-agency__main__item__logo'>
                          <Col lg={6} md={24} sm={24} xs={24} className='intro-agency__main__item__logo__item-logo'>
                            <img src={item.logo} alt='logo' className='intro-agency__main__item__logo__main'></img>
                          </Col>
                          <Col lg={18} md={24} sm={24} xs={24} className='intro-agency__main__item__logo__item'>
                            <div className='intro-agency__main__item__detail'>
                              <h3>{item.name}</h3>
                              <div className='intro-agency__main__item-main'>
                                <a href={`${item.coordinates}`} className='intro-agency__main__item__child__item'
                                  target='_blank' rel='noopener noreferrer'
                                >
                                  <i className='fas fa-map-marker-alt'></i>
                                  {item.address}
                                </a>
                                <div className='intro-agency__main__item__child intro-agency__main__item__child__logo'>
                                  {
                                    item.phone &&
                                    <a href={`tel:${item.phone}`} className='intro-agency__main__item__child__item intro-agency__main__item__child__logo__item'>
                                      <i className='fas fa-phone'></i>
                                      <p>{item.phone}</p>
                                    </a>
                                  }
                                  {
                                    item.fax &&
                                    <div className='intro-agency__main__item__child__item intro-agency__main__item__child__logo__item'>
                                      <i className='fas fa-fax'></i>
                                      <p>{item.fax}</p>
                                    </div>
                                  }
                                </div>
                                {
                                  item.email &&
                                  <a href={`mailto:${item.email}`} className='intro-agency__main__item__child__item'>
                                    <i className='fas fa-envelope'></i>
                                    <p>{item.email}</p>
                                  </a>
                                }
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className='intro-agency__main__item__decor'></div>
                      </Col>
                    );
                  else
                    return (
                      <Col className='intro-agency__main-container' lg={8} md={12} sm={24} xs={24} key={index + item.name}>
                        <div className='intro-agency__main__item'>
                          <h3>{item.name}</h3>
                          <div className='intro-agency__main__item-main'>
                            {!item.address ?
                              <p><strong>Coming soon</strong></p> :
                              <a href={`${item.coordinates}`} className='intro-agency__main__item__child__item'
                                target='_blank' rel='noopener noreferrer'
                              >
                                <i className='fas fa-map-marker-alt'></i>
                                {item.address}
                              </a>
                            }
                            <div className='intro-agency__main__item__child'>
                              {
                                item.phone &&
                                <a href={`tel:${item.phone}`} className='intro-agency__main__item__child__item'>
                                  <i className='fas fa-phone'></i>
                                  <p>{item.phone}</p>
                                </a>
                              }
                              {
                                item.fax &&
                                <div className='intro-agency__main__item__child__item'>
                                  <i className='fas fa-fax'></i>
                                  <p>{item.fax}</p>
                                </div>
                              }
                            </div>
                            {
                              item.email &&
                              <a href={`mailto:${item.email}`} className='intro-agency__main__item__child__item'>
                                <i className='fas fa-envelope'></i>
                                <p>{item.email}</p>
                              </a>
                            }
                          </div>
                        </div>
                        <div className='intro-agency__main__item__decor'></div>
                      </Col>
                    );
                })}
              </Row>
            </div>
            <div className="intro__main__button-container">
            <Link to={`/${props.language}/intro`}>
              <IntroButton title={IntroCommon[props.language].backIntro} color="white"></IntroButton>
            </Link>
          </div>
          </div>
        </div>
      </div>
    </Helmet>
  );
}
