import posco from "../../../asset/img/posco.jpg";
import hyndai from "../../../asset/img/huyndai_steel.png";
import tungho from "../../../asset/img/tunghogroup.jpg";
import jsw from "../../../asset/img/jsw.png";
import tisco from "../../../asset/img/tisco.jpg";
import pfs from "../../../asset/img/pfs.png";
import nippon from "../../../asset/img/nippon-steel.png";
import pomina from "../../../asset/img/POMINA.png";
import hoaphat from "../../../asset/img/hoaphat.png";
import logo from "../../../asset/img/Logo.png";

export const FooterCommon = {
  vi: {
    CooperationUnitTitle: "CÁC ĐƠN VỊ HỢP TÁC",
    FooterTitle: [
      {
        title: "Tập đoàn thép Tiến Lên",
        sub: [
          {
            path: "/vi/intro/about",
            title: "Tổng quan về Tập Đoàn",
          },
          {
            path: "/vi/intro/field",
            title: "Lĩnh vực kinh doanh",
          },
          {
            path: "/vi/intro/organization",
            title: "Sơ đồ tổ chức",
          },
          {
            path: "/vi/intro/agency",
            title: "Đơn vị trực thuộc",
          },
          {
            path: "/vi/intro/achieve",
            title: "Thành tích - Chứng nhận",
          },
          {
            path: "/vi/intro/values",
            title: "Lịch sử hình thành, Tầm nhìn và giá trị cốt lõi",
          },
        ],
      },
      {
        title: "Quan hệ cổ đông",
        sub: [
          {
            path: "/vi/relation/0",
            title: "Công bố thông tin",
          },
          {
            path: "/vi/relation/1",
            title: "Điều lệ quy chế quản trị",
          },
          {
            path: "/vi/relation/2",
            title: "Nghị quyết - Nghị định",
          },
          {
            path: "/vi/relation/3",
            title: "Báo cáo tài chính",
          },
          {
            path: "/vi/relation/4",
            title: "Báo cáo quản trị",
          },
          {
            path: "/vi/relation/5",
            title: "Báo cáo thường niên",
          },
          {
            path: "/vi/relation/6",
            title: "Tài liệu Đại Hội Cổ Đông",
          },
          {
            path: "/vi/relation/7",
            title: "Bản cáo bạch",
          },
          {
            path: "/vi/relation/8",
            title: "Văn bản pháp lí",
          },
          {
            title: "Trợ giúp cổ đông",
            path: "/vi/contact?isInvestorRelation=true",
          },
        ],
      },
      {
        title: "Tin tức",
        sub: [
          {
            path: "/vi/news/NWSG001",
            title: "Thị trường sắt thép",
          },
          {
            title: "Thông tin công ty",
            sub: [
              {
                path: "/vi/news/NWSG002",
                title: "Thông cáo báo chí - Tin tức hoạt động",
              },
              {
                path: "/vi/news/NWSG003",
                title: "Tin cổ phiếu",
              },
            ],
          },
        ],
      },
      {
        title: "Liên kết nhanh",
        sub: [
          {
            path: "/vi/recruit",
            title: "Tuyển dụng",
          },
          {
            link: "https://product.tienlensteel.com.vn/vi/products",
            title: "Sản phẩm",
          },
          {
            link: "https://product.tienlensteel.com.vn/vi/policy/security",
            title: "Hỗ trợ khách hàng",
          },
          {
            path: "/vi/intro/agency",
            title: "Hệ thống phân phối",
          },
          {
            path: "/vi/contact",
            title: "Liên hệ",
          },
          {
            path: "/vi/DownloadDocument",
            title: "Quản lý tài liệu",
          },
        ],
      },
    ],
    FooterEnding: {
      logo,
      title: "CÔNG TY CỔ PHẦN TẬP ĐOÀN THÉP TIẾN LÊN - TRỤ SỞ CHÍNH",
      address:
        "G4A Khu Phố 4, Phường Tân Hiệp, Thành Phố Biên Hòa, Tỉnh Đồng Nai",
      phone: "(+84) 251 382 3187",
    },
    FooterPolicy: {
      copyright:
        "Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved",
      policy: "Điều khoản sử dụng",
    },
  },
  en: {
    CooperationUnitTitle: "Our Partners",
    FooterTitle: [
      {
        title: "Tien Len Steel Corporation",
        sub: [
          {
            path: "/en/intro/about",
            title: "General Introduction",
          },
          {
            path: "/en/intro/field",
            title: "Business Areas",
          },
          {
            path: "/en/intro/organization",
            title: "Organization Chart",
          },
          {
            path: "/en/intro/agency",
            title: "Affiliated companies",
          },
          {
            path: "/en/intro/achieve",
            title: "Awards and Honors",
          },
          {
            path: "/en/intro/values",
            title: "Milestone, Vision, Core Values",
          },
        ],
      },
      {
        title: "Investor Relations",
        sub: [
          {
            title: "IR News",
            path: "/en/relation/0",
          },
          {
            title: "Corporate Governance",
            path: "/en/relation/1",
          },
          {
            title: "Resolution",
            path: "/en/relation/2",
          },
          {
            title: "Financial Report",
            path: "/en/relation/3",
          },
          {
            title: "Management Report",
            path: "/en/relation/4",
          },
          {
            title: "Annual Report",
            path: "/en/relation/5",
          },
          {
            title: "Documents for Shareholders' Meeting",
            path: "/en/relation/6",
          },
          {
            title: "Prospectus",
            path: "/en/relation/7",
          },
          {
            title: "Legal Documents",
            path: "/en/relation/8",
          },
          {
            title: "Contact Us",
            path: "/en/contact?isInvestorRelation=true",
          },
        ],
      },
      {
        title: "Media",
        sub: [
          {
            path: "/en/news/NWSG001",
            title: "Steel Market",
          },
          {
            path: "/en/about",
            title: "Corporate News",
            sub: [
              {
                path: "/en/news/NWSG002",
                title: "Press Release",
              },
              {
                path: "/en/news/NWSG003",
                title: "Stock News",
              },
            ],
          },
        ],
      },
      {
        title: "Quick Links",
        sub: [
          {
            path: "/en/recruit",
            title: "Recruitment",
          },
          {
            link: "https://product.tienlensteel.com.vn/en/products",
            title: "Products",
          },
          {
            link: "https://uat-landing.tienlensteel.com.vn/en/policy/security",
            title: "Customer Support",
          },
          {
            path: "/en/intro/agency",
            title: "Distribution Network",
          },
          {
            path: "/en/contact",
            title: "Contact",
          },
        ],
      },
    ],
    FooterEnding: {
      logo,
      title: "Tien Len Steel Corporation Joint Stock Company - Head Office",
      address:
        "G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province",
      phone: "(+84) 251 382 3187",
    },
    FooterPolicy: {
      copyright:
        "Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved",
      policy: "Terms of use",
    },
  },
};

export const HeaderCommon = {
  vi: {
    HeaderTitleHeading: [
      {
        path: "/vi/contact",
        title: "Liên hệ",
      },
    ],
    HeaderTitleMain: [
      {
        path: "/vi",
        title: "Trang chủ",
      },
      {
        path: "/vi/intro",
        title: "Giới thiệu",
        sub: [
          {
            title: "Tổng quan về Tập Đoàn",
            path: "/vi/intro/about",
          },
          {
            title: "Lĩnh vực kinh doanh",
            path: "/vi/intro/field",
          },
          {
            title: "Sơ đồ tổ chức",
            path: "/vi/intro/organization",
          },
          {
            title: "Đơn vị trực thuộc",
            path: "/vi/intro/agency",
          },
          {
            title: "Thành tích - Chứng nhận",
            path: "/vi/intro/achieve",
          },
          {
            title: "Lịch sử hình thành - Tầm nhìn - Giá trị cốt lõi",
            path: "/vi/intro/values",
          },
        ],
      },
      {
        link: "https://product.tienlensteel.com.vn/vi/products",
        title: "Sản phẩm",
      },
      {
        path: "/vi/relation",
        title: "Quan hệ cổ đông",
        sub: [
          {
            title: "Công bố thông tin",
            path: "/vi/relation/0",
          },
          {
            title: "Điều lệ quy chế quản trị",
            path: "/vi/relation/1",
          },
          {
            title: "Nghị quyết - Nghị định",
            path: "/vi/relation/2",
          },
          {
            title: "Báo cáo tài chính",
            path: "/vi/relation/3",
          },
          {
            title: "Báo cáo quản trị",
            path: "/vi/relation/4",
          },
          {
            title: "Báo cáo thường niên",
            path: "/vi/relation/5",
          },
          {
            title: "Tài liệu Đại Hội Cổ Đông",
            path: "/vi/relation/6",
          },
          {
            title: "Bản cáo bạch",
            path: "/vi/relation/7",
          },
          {
            title: "Văn bản pháp lí",
            path: "/vi/relation/8",
          },
          {
            title: "Trợ giúp cổ đông",
            path: "/vi/contact?isInvestorRelation=true",
          },
        ],
      },
      {
        path: "/vi/news",
        title: "Tin tức",
        sub: [
          {
            title: "Thị trường sắt thép",
            path: "/vi/news/thi-truong-sat-thep",
          },
          {
            title: "Thông tin công ty",
            sub: [
              {
                title: "Thông cáo báo chí - Tin tức hoạt động",
                path: "/vi/news/thong-cao-bao-chi",
              },
              {
                title: "Tin cổ phiếu",
                path: "/vi/news/tin-co-phieu",
              },
            ],
          },
        ],
      },
      {
        path: "/vi/recruit",
        title: "Tuyển dụng",
      },
      {
        path: "/vi/DownloadDocument",
        title: "Tài liệu",
      },
    ],
  },
  en: {
    HeaderTitleHeading: [
      {
        path: "/en/contact",
        title: "Contact Us",
      },
    ],
    HeaderTitleMain: [
      {
        path: "/en",
        title: "Home",
      },
      {
        path: "/en/intro",
        title: "About Us",
        sub: [
          {
            title: "General Introduction",
            path: "/en/intro/about",
          },
          {
            title: "Business Areas",
            path: "/en/intro/field",
          },
          {
            title: "Organization chart",
            path: "/en/intro/organization",
          },
          {
            title: "Affiliated companies",
            path: "/en/intro/agency",
          },
          {
            title: "Awards and Honors",
            path: "/en/intro/achieve",
          },
          {
            title: "Milestone, Vision, Core Values",
            path: "/en/intro/values",
          },
        ],
      },
      {
        link: "https://product.tienlensteel.com.vn/en/products",
        title: "Products",
      },
      {
        path: "/en/relation",
        title: "Investor Relations",
        sub: [
          {
            title: "IR News",
            path: "/en/relation/0",
          },
          {
            title: "Corporate Governance",
            path: "/en/relation/1",
          },
          {
            title: "Resolution",
            path: "/en/relation/2",
          },
          {
            title: "Financial Report",
            path: "/en/relation/3",
          },
          {
            title: "Management Report",
            path: "/en/relation/4",
          },
          {
            title: "Annual Report",
            path: "/en/relation/5",
          },
          {
            title: "Documents for Shareholders’ Meeting",
            path: "/en/relation/6",
          },
          {
            title: "Prospectus",
            path: "/en/relation/7",
          },
          {
            title: "Legal Documents",
            path: "/en/relation/8",
          },
          {
            title: "Contact Us",
            path: "/en/contact?isInvestorRelation=true",
          },
        ],
      },
      {
        path: "/en/news",
        title: "News and Media",
        sub: [
          {
            title: "Steel Market",
            path: "/en/news/NWSG001",
          },
          {
            title: "Corporate News",
            sub: [
              {
                title: "Press Release",
                path: "/en/news/NWSG002",
              },
              {
                title: "Stock News",
                path: "/en/news/NWSG003",
              },
            ],
          },
        ],
      },
      {
        path: "/en/recruit",
        title: "Recruitment",
      },
      {
        path: "/en/DownloadDocument",
        title: "Document",
      },
    ],
  },
};

export const CooperationUnit = [
  {
    src: posco,
    width: 155,
  },
  {
    src: hyndai,
    // width: 245,
  },
  {
    src: tungho,
    // width: 105,
  },
  {
    src: jsw,
    // width: 146,
  },
  {
    src: tisco,
    // width: 109,
  },
  {
    src: pfs,
    // width: 106,
  },
  {
    src: nippon,
    // width: 110,
  },
  {
    src: pomina,
    width: 155,
  },
  {
    src: hoaphat,
    width: 180,
  },
];

// Contact
export const contactMedia = {
  phone: "+84 2513823187",
  email: "info@tienlen.com.vn",
  facebook: 'https://www.facebook.com/people/Th%C3%A9p-Ti%E1%BA%BFn-L%C3%AAn-Tien-Len-Steel/100091320035096/?gidzl=TA7e9YBqNZ1rlkqbAknSDdsxxYKql0a-Ok3lA32oMcXciBvpO-LUDZ7dltStjLbXPxI_SJTdP88Z8V1JCW'
  // facebook: "#",
};
