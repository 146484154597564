import React, { useState } from "react";
import HomeSliderItem from "./HomeSliderItem";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import { ManageBannerService } from "../../Admin/ManageBanner/Service/ManageBanner.Service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  return (
    <div className="arrow-slider arrow-slider--next" onClick={props.onClick}>
      <RightOutlined />
    </div>
  );
};

const PrevArrow = (props) => {
  return (
    <div className="arrow-slider arrow-slider--prev" onClick={props.onClick}>
      <LeftOutlined />
    </div>
  );
};

const settings = {
  customPaging() {
    return <div className="slider--dots"></div>;
  },
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow></NextArrow>,
  prevArrow: <PrevArrow></PrevArrow>,
  autoplay: true,
  autoplaySpeed: 8000,
};

export default function HomeSlider(props) {
  const [dataSource, setDataSource] = React.useState([]);
  const [flagLoading, setFlagLoading] = useState(false);
  const { language } = props;
  React.useEffect(() => {
    setFlagLoading(true)
    const searchOption = {
      limit: 10,
      page: 1,
    };
    const searchModel = {
      News_Sort: false,
      Language: language,
    };
    ManageBannerService.getImagesForLandingPage(searchModel, searchOption).then(
      (result) => {
        setDataSource(result?.result?.docs);
        setFlagLoading(false)
      }
    ).catch(()=>{
      setFlagLoading(false)
    });
  }, [language]);
  const onHandleShowItemSlider = () => {
    let result = <div className="home__heading__slide__item" style={{ height: `100vh` }}></div>;
    result = dataSource.map((item, index) => {
      return (
        <HomeSliderItem
          key={index + item?.Banner_Title}
          background={`${process.env.REACT_APP_API_URL}/${item?.Banner_Images_PC[0]?.url}`}
          backgroundTablet={`${process.env.REACT_APP_API_URL}/${item?.Banner_Images_Tablet[0]?.url}`}
          backgroundMobile={`${process.env.REACT_APP_API_URL}/${item?.Banner_Images_Mobile[0]?.url}`}
          // title={props.language === "vi" ? item?.Banner_Title : item?.Banner_Title_Eng}
          subtitle={item?.Banner_Slogan}
          language={props.language}
          link={item?.Banner_Link}
        ></HomeSliderItem>
      );
    });
    return result;
  };
  return (
    <Slider {...settings} className="home__heading__slide">
      {flagLoading ? <div className="home__heading__slide__item" style={{ height: `100vh` }}></div> : onHandleShowItemSlider()}
    </Slider>
  );
}
